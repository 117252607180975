import React, { useState } from "react";
import "../styles/HomeStyles.css";
import { GoArrowRight } from "react-icons/go";
import Modal from "react-modal";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";

const Home = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const videoLinks = {
    Athlantix: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
    Roam: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
    Velara: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
    "Sky AI": "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
    CareGen: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
    Creta: "https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1&mute=1",
  };

  const openModal = (videoName) => {
    setActiveVideo(videoName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setActiveVideo(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>

        {/* section one */}
        <div className="flex flex-col items-center justify-center px-8 pt-20 space-y-20 md:px-16 lg:px-24 xl:px-48 md:pt-40 lg:pt-80 md:space-y-40 lg:space-y-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              venture studio
            </h1>
            <h1 className="text-[#303030] text-2xl md:text-4xl text-center font-gilroyBold">
              <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
                Simplify your
              </span>{" "}
              Product launch <br />
              <span className="underline decoration-2 decoration-[#303030] underline-offset-2 font-normal font-gilroyRegular">
                and
              </span>{" "}
              Distribution
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center space-y-8">
            {Object.keys(videoLinks).map((name) => (
              <h1
                key={name}
                className="text-[#303030] text-3xl md:text-5xl lg:text-6xl font-normal relative after:content-[''] after:block after:h-[2px] after:bg-[#303030] after:mt-4 after:mx-auto hover:cursor-pointer"
                onMouseEnter={() => openModal(name)}
              >
                {name} .
              </h1>
            ))}

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Video Modal"
              className="flex items-center justify-center outline-none"
              overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center"
              onClick={closeModal}
            >
              <div onClick={(e) => e.stopPropagation()}>
                {activeVideo && (
                  <iframe
                    src={videoLinks[activeVideo]}
                    allow="autoplay; fullscreen"
                    className="w-[90vw] h-[50vh] md:w-[75vw] md:h-[60vh] lg:w-[140vh] lg:h-[80vh]"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                )}
                <button
                  onClick={closeModal}
                  className="absolute p-2 text-2xl text-white rounded-full top-2 right-2"
                >
                  ✕
                </button>
              </div>
            </Modal>
          </div>
        </div>

        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-24 xl:px-48">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                example@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
              <Link
                to={"/categories"}
                className="text-sm md:text-md text-[#d6d2cd]"
              >
                Blogs
              </Link>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center pt-8 space-x-1 md:pt-0 hover:cursor-pointer ">
                <h1 className="text-[#e6e2dd] text-lg md:text-xl font-semibold ">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <img
                alt="behance"
                src="./images/behance_logo.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
              <img
                alt="x"
                src="./images/x_logo.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
              <img
                alt="football"
                src="./images/footbal-logo.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
              <img
                alt="linkedin"
                src="./images/linkedin_symbol.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
              <img
                alt="insta"
                src="./images/insta-logo.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
              <img
                alt="youtube"
                src="./images/youtube-logo.png"
                className="w-8 h-auto md:w-12 lg:w-full"
              />
            </div>
            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
