import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../components/ScrollToTop";
import { FaAngleRight } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../client";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

// Initialize the image URL builder from Sanity
const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Blog = () => {
  const { blogName } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [contentSequence, setContentSequence] = useState([]);

  const { categoryName } = location.state || {}; // Access the passed state

  //-----------------------------------GETTING POST DATA-----------------------------------------//

  // Function to extract the content sequence
  const extractContentSequence = (data) => {
    const sequence = [];

    // Extract main image at the top
    if (data.mainImage) {
      sequence.push({ type: "image", src: urlFor(data.mainImage.asset).url() });
    }

    // Loop through the body to extract paragraphs, headings, and inline images
    data.body?.forEach((block) => {
      if (block._type === "block") {
        if (block.style === "normal") {
          sequence.push({
            type: "paragraph",
            content: block.children.map((child) => child.text).join(" "),
          });
        } else if (block.style === "h1") {
          // Handle h1 separately with specific type and level
          sequence.push({
            type: "heading1", // Change type for h1
            level: "h1",
            content: block.children.map((child) => child.text).join(" "),
          });
        } else if (block.style === "h2") {
          // Handle h2 separately with specific type and level
          sequence.push({
            type: "heading2", // Change type for h2
            level: "h2",
            content: block.children.map((child) => child.text).join(" "),
          });
        } else if (block.style === "h3") {
          // Handle h3 separately with specific type and level
          sequence.push({
            type: "heading3", // Change type for h3
            level: "h3",
            content: block.children.map((child) => child.text).join(" "),
          });
        }
        // Continue adding more heading levels if needed (h4, h5, etc.)
      } else if (block._type === "image") {
        // Check for images in the body
        sequence.push({
          type: "image",
          src: urlFor(block.asset).url(),
        });
      }
    });

    // Extract additional images
    if (data.additionalImages) {
      data.additionalImages.forEach((img) => {
        sequence.push({
          type: "image",
          src: urlFor(img.asset).url(),
        });
      });
    }

    // Log the final sequence of content
    console.log(sequence);
    setContentSequence(sequence);
  };

  const structuredContent = [];
  let currentSection = {
    heading: "",
    paragraph: "",
    image: "",
    subHeadings: [],
  };
  let lastH2Section = null; // To keep track of the last h2 section for nesting h3 headings
  let imageIncluded = false; // Flag to track if the first image has been encountered

  // Iterate over the content array
  contentSequence.forEach((item) => {
    if (item.type.startsWith("heading")) {
      // If it's an h1 or h2, finalize the previous section
      if (item.level === "h1" || item.level === "h2") {
        // Push the previous section if it's complete
        if (
          currentSection.heading ||
          currentSection.paragraph ||
          currentSection.image
        ) {
          structuredContent.push(currentSection);
        }

        // Create a new section for h1 or h2
        currentSection = {
          heading: item.content,
          paragraph: "",
          image: "",
          subHeadings: [],
        };

        // If it's an h2, we track it to potentially add h3 headings later
        if (item.level === "h2") {
          lastH2Section = currentSection;
        }
      } else if (item.level === "h3") {
        // If it's an h3 and there is a tracked h2 section, add it to the subHeadings of the current h2
        if (lastH2Section) {
          lastH2Section.subHeadings.push({
            heading: item.content,
            paragraph: "",
            image: "",
          });
        }
      }
    } else if (item.type === "paragraph") {
      // Add paragraph to the current section or subheading
      if (lastH2Section && lastH2Section.subHeadings.length > 0) {
        // If there is an h3, add the paragraph to the last subHeading (h3)
        const lastSubHeading =
          lastH2Section.subHeadings[lastH2Section.subHeadings.length - 1];
        lastSubHeading.paragraph += item.content;
      } else if (currentSection.heading) {
        // Otherwise, add the paragraph to the current section
        currentSection.paragraph += item.content;
      } else {
        // If no heading exists, this is a standalone paragraph
        structuredContent.push({
          heading: "",
          paragraph: item.content,
          image: "",
        });
      }
    } else if (item.type === "image") {
      // Skip the first image (main image)
      if (!imageIncluded) {
        imageIncluded = true; // Set flag to true after the first image is encountered
      } else {
        // Add subsequent images to the current section or subheading
        if (lastH2Section && lastH2Section.subHeadings.length > 0) {
          // Add image to the last subheading (h3)
          const lastSubHeading =
            lastH2Section.subHeadings[lastH2Section.subHeadings.length - 1];
          lastSubHeading.image = item.src;
        } else {
          // Add image to the current section
          currentSection.image = item.src;
        }
      }
    }
  });

  // Push the last section if it exists
  if (
    currentSection.heading ||
    currentSection.paragraph ||
    currentSection.image
  ) {
    structuredContent.push(currentSection);
  }

  console.log(structuredContent);

  const [postData, setPostData] = useState(null); // State to store the fetched post data

  useEffect(() => {
    if (blogName) {
      // Fetch the post data using the slug
      sanityClient
        .fetch(
          `*[slug.current == "${blogName}"]{
            title,
            mainHeading,
            subHeadings[]{heading, text},
            slug,
            mainImage{asset->{_id, url}},
            popularPosts[]{rank, postTitle, link},
            additionalImages,
            backgroundImage{asset->{_id, url}},
            author->{name, image, bio},
            publishedAt,
            categories[]->{_ref, title, description},
            body,
            websites[]->{name},
            backgroundColor,
            textColor,
            video{asset->{_id, url}},
            videos[]{title, description, link},
            firstHeading,
            secondHeading,
            links,
            embeddedContent,
            excerpt,
            tags,
            isFeatured
          }`
        )
        .then((data) => {
          setPostData(data[0]); // Store the post data in state
          setLoading(false);
          if (data[0]) {
            extractContentSequence(data[0]);
          }
        })
        .catch(console.error);
    }
  }, [blogName]); // Re-fetch if slug changes

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }
  //-------------------------------------------serializer---------------------------------------//

  // const serializers = {
  //   types: {
  //     block: (props) => {
  //       switch (props.node.style) {
  //         case "h1":
  //           return <h1 className="text-4xl font-bold">{props.children}</h1>;
  //         case "h2":
  //           return <h2 className="text-3xl font-semibold">{props.children}</h2>;
  //         case "h3":
  //           return <h3 className="text-2xl font-medium">{props.children}</h3>;
  //         case "blockquote":
  //           return (
  //             <blockquote className="pl-4 my-4 italic border-l-4 border-gray-200">
  //               {props.children}
  //             </blockquote>
  //           );
  //         default:
  //           return <p className="text-base">{props.children}</p>;
  //       }
  //     },
  //     image: (props) => {
  //       const imageUrl = urlFor(props.node.asset).url();
  //       return (
  //         <div className="my-4">
  //           <img src={imageUrl} alt={props.node.alt || "Sanity Image"} />
  //           {props.node.caption && (
  //             <p className="text-sm text-center">{props.node.caption}</p>
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  //   marks: {
  //     link: (props) => (
  //       <a
  //         href={props.mark.href}
  //         className="text-blue-500 underline hover:text-blue-700"
  //       >
  //         {props.children}
  //       </a>
  //     ),
  //     strong: (props) => <strong>{props.children}</strong>,
  //     em: (props) => <em>{props.children}</em>,
  //   },
  // };

  return (
    <>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12  min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
          <Link to={"/"}>
            {" "}
            <img
              alt="axillio-logo"
              src="./images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
        </div>
        <div className="flex flex-row items-center px-4 pt-8 space-x-2 md:px-16 lg:px-32 xl:px-48 md:pt-16">
          {/* Link to the blogs home page */}
          <Link
            to="/categories"
            className="text-[#303030] text-md md:text-xl font-normal cursor-pointer no-underline transition-all duration-300 hover:text-[#505050] hover:underline"
          >
            <h1 className="text-[#303030] text-md md:text-xl font-normal">
              Blogs
            </h1>
          </Link>
          <FaAngleRight className="text-[#303030] text-md md:text-xl transition-all duration-300 hover:text-[#505050]" />

          {/* Link to the category page */}
          <Link
            to={`/blogs/${categoryName}`}
            className="text-[#303030] text-md md:text-xl font-normal cursor-pointer no-underline transition-all duration-300 hover:text-[#505050] hover:underline"
          >
            <h1 className="text-[#303030] text-md md:text-xl font-normal">
              {categoryName}
            </h1>
          </Link>
          <FaAngleRight className="text-[rgb(48,48,48)] text-md md:text-xl transition-all duration-300 hover:text-[#505050]" />

          {/* Link to the specific blog post */}
          <Link
            to={`/blogs/${categoryName}/${blogName}`}
            className="text-[#303030] text-md md:text-xl font-semibold cursor-pointer no-underline transition-all duration-300 hover:text-[#505050] hover:underline hover:translate-x-1"
          >
            <h1 className="text-[#303030] text-md md:text-xl font-semibold">
              {blogName
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h1>
          </Link>
        </div>

        {/* section one */}
        <div className="flex flex-col items-center justify-center px-4 pt-20 space-y-16 md:px-16 lg:px-32 xl:px-48 md:pt-40 lg:pt-56 md:space-y-24 lg:space-y-36">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold transition-all duration-300 hover:text-[#505050]">
              {categoryName}
            </h1>
            <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold transition-all duration-300 hover:text-[#505050] hover:scale-105">
              {blogName
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center w-full md:w-[120vh] lg:w-[140vh] px-4 md:px-8 lg:px-0">
            {/* main image */}
            {postData.mainImage && (
              <img
                alt="main"
                src={urlFor(postData.mainImage.asset).url()}
                className="w-full h-auto md:w-[120vh] lg:w-[140vh] transition-transform duration-300 hover:scale-105"
              />
            )}
            {structuredContent.length > 0 &&
              structuredContent.map((section, index) => {
                const sectionType = index % 4; // This will help repeat the sections in the same sequence

                return (
                  <div key={index}>
                    {section.subHeadings && section.subHeadings.length > 0 ? (
                      <div className="flex flex-col pt-12 pb-6 space-y-4 border-b-2 border-[#8b8986]">
                        {/* Main Content (Heading, Paragraph, Image) */}
                        <div className="flex flex-col space-y-4">
                          <h1 className="text-[#303030] font-semibold text-lg md:text-2xl transition-all duration-300 hover:text-[#505050]">
                            {section.heading}
                          </h1>
                          <h1 className="text-[#797775] text-md md:text-xl">
                            {section.paragraph}
                          </h1>

                          {/* Main Image (if available) */}
                          {section.image && (
                            <img
                              alt="Main Section Image"
                              src={section.image}
                              className="w-full md:w-[80vh] lg:w-[100vh] h-auto transition-transform duration-300 hover:scale-105"
                            />
                          )}
                        </div>

                        {/* Subheadings Section */}
                        <div className="flex flex-col py-6 ">
                          {section.subHeadings.map((subHeading, index) => (
                            <div key={index} className="pb-6 pl-4 space-y-2">
                              <h2 className="text-[#505050] font-semibold text-lg md:text-xl transition-all duration-300 hover:text-[#303030]">
                                {subHeading.heading}
                              </h2>
                              <p className="text-[#797775] text-md">
                                {subHeading.paragraph}
                              </p>
                              {subHeading.image && (
                                <div className="flex justify-center pt-10">
                                  <img
                                    alt="Subheading Image"
                                    src={subHeading.image}
                                    className="w-full md:w-[80vh] lg:w-[100vh] h-auto transition-transform duration-300 hover:scale-105"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Section 1 */}
                        {sectionType === 0 && (
                          <div className="flex flex-col md:flex-row items-center py-10 space-y-4 md:space-y-0 md:space-x-4 border-b-2 border-[#8b8986]">
                            <div className="flex flex-col space-y-4 md:space-y-8">
                              <h1 className="text-[#303030] font-semibold text-lg md:text-2xl transition-all duration-300 hover:text-[#505050]">
                                {section.heading}
                              </h1>
                              <h1 className="text-[#797775] text-md md:text-xl">
                                {section.paragraph}
                              </h1>
                            </div>
                            {section.image && (
                              <img
                                alt="1"
                                src={section.image}
                                className="w-full md:w-[60vh] h-auto transition-transform duration-300 hover:scale-105"
                              />
                            )}
                          </div>
                        )}

                        {/* Section 2 */}
                        {sectionType === 1 && (
                          <div className="flex flex-col md:flex-row py-10 space-y-4 md:space-y-0 md:space-x-1 border-b-2 border-[#8b8986]">
                            <div className="flex flex-col pr-4 space-y-4 md:space-y-8 w-full md:w-[60%]">
                              {section.image && (
                                <img
                                  alt="1"
                                  src={section.image}
                                  className="w-full md:w-[80vh] h-auto transition-transform duration-300 hover:scale-105"
                                />
                              )}
                              <h1 className="text-[#303030] font-semibold text-lg md:text-2xl transition-all duration-300 hover:text-[#505050]">
                                {section.heading}
                              </h1>

                              {/* Split paragraph into words */}
                              {(() => {
                                const words = section.paragraph.split(" ");
                                const halfwayIndex = Math.floor(
                                  words.length / 2
                                );
                                const firstHalf = words
                                  .slice(0, halfwayIndex)
                                  .join(" ");

                                return (
                                  <h1 className="text-[#797775] text-md md:text-xl">
                                    {firstHalf}
                                  </h1>
                                );
                              })()}
                            </div>

                            <div className="w-full md:w-[40%]">
                              {/* Second part of the paragraph on the right */}
                              {(() => {
                                const words = section.paragraph.split(" ");
                                const halfwayIndex = Math.floor(
                                  words.length / 2
                                );
                                const secondHalf = words
                                  .slice(halfwayIndex)
                                  .join(" ");

                                return (
                                  <h1 className="text-[#797775] text-md md:text-xl">
                                    {secondHalf}
                                  </h1>
                                );
                              })()}
                            </div>
                          </div>
                        )}

                        {/* Section 3 */}
                        {sectionType === 2 && (
                          <div className="flex flex-col md:flex-row items-center py-10 space-y-4 md:space-y-0 md:space-x-4 border-b-2 border-[#8b8986]">
                            {section.image && (
                              <img
                                alt="1"
                                src={section.image}
                                className="w-full md:w-[60vh] h-auto transition-transform duration-300 hover:scale-105"
                              />
                            )}
                            <div className="flex flex-col space-y-4 md:space-y-8">
                              <h1 className="text-[#303030] font-semibold text-lg md:text-2xl transition-all duration-300 hover:text-[#505050]">
                                {section.heading}
                              </h1>
                              <h1 className="text-[#797775] text-md md:text-xl">
                                {section.paragraph}
                              </h1>
                            </div>
                          </div>
                        )}

                        {/* Section 4 */}
                        {sectionType === 3 && (
                          <div className="flex flex-col md:flex-row items-center py-10 space-y-4 md:space-y-0 md:space-x-4 border-b-2 border-[#8b8986]">
                            {(() => {
                              const words = section.paragraph.split(" ");
                              const halfwayIndex = Math.floor(words.length / 2);
                              const firstHalf = words
                                .slice(0, halfwayIndex)
                                .join(" ");
                              const secondHalf = words
                                .slice(halfwayIndex)
                                .join(" ");

                              return (
                                <>
                                  <div
                                    className={`flex flex-col space-y-4 md:space-y-8 ${
                                      section.image
                                        ? "w-full md:w-[30%]"
                                        : "w-full"
                                    }`}
                                  >
                                    <h1 className="text-[#303030] font-semibold text-lg md:text-2xl transition-all duration-300 hover:text-[#505050]">
                                      {section.heading}
                                    </h1>
                                    <h1 className="text-[#797775] text-md md:text-xl">
                                      {firstHalf}
                                    </h1>
                                  </div>

                                  {section.image && (
                                    <div className="flex justify-center w-full md:w-[40%]">
                                      <img
                                        alt="1"
                                        src={section.image}
                                        className="w-full md:w-[60vh] h-auto transition-transform duration-300 hover:scale-105"
                                      />
                                    </div>
                                  )}

                                  <div
                                    className={`flex flex-col space-y-4 md:space-y-8 ${
                                      section.image
                                        ? "w-full md:w-[30%]"
                                        : "w-full"
                                    }`}
                                  >
                                    <h1 className="text-[#797775] text-md md:text-xl">
                                      {secondHalf}
                                    </h1>
                                  </div>
                                </>
                              );
                            })()}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>

        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                example@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <img
                alt="behance"
                src="./images/behance_logo.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
              <img
                alt="x"
                src="./images/x_logo.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
              <img
                alt="football"
                src="./images/footbal-logo.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
              <img
                alt="linkedin"
                src="./images/linkedin_symbol.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
              <img
                alt="insta"
                src="./images/insta-logo.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
              <img
                alt="youtube"
                src="./images/youtube-logo.png"
                className="w-6 h-auto md:w-8 lg:w-full"
              />
            </div>
            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
