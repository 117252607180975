import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Categories from "./pages/Categories";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";

function App() {
  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/blogs/:categoryName" element={<Blogs />} />
          <Route path="/:blogName" element={<Blog />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
